import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { TranslationProviderService } from './app/translation-provider.service';
import { loadTranslations, clearTranslations } from '@angular/localize';
import '@angular/localize/init';

if (environment.production){
	enableProdMode();
}

// document.addEventListener('DOMContentLoaded', () => {
// 	platformBrowserDynamic()
// 		.bootstrapModule(AppModule)
// 		.catch(err => console.error(err));
// });

const translationService = new TranslationProviderService();

translationService.getTranslation().then((translations) => {

	if(translations){
		try {
			Object.keys(translations).forEach(key => {
				if( translations[key] === undefined) {
					delete  translations[key];
				}
			});
			loadTranslations(translations);
		}catch(e) {
			console.error('Translation load error!', e)
		}
		
	}

	import('./app/app.module').then(module => {
		platformBrowserDynamic()
			.bootstrapModule(module.AppModule)
			.catch(err => console.error(err));
	});
});
